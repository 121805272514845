<template>
  <v-container
    fluid
    class="white-background main-detail-page"
    :class="`${customClass} ${!fullscreenClass ? 'fullscreen' : ''}`"
  >
    <template v-if="$slots['header-topbar']">
      <slot name="header-topbar"></slot>
    </template>
    <template v-if="$slots['header-filter']">
      <slot name="header-filter"></slot>
    </template>
    <div class="d-flex">
      <template>
        <slot name="sidebar-listing"></slot>
      </template>
      <v-flex class="details-section">
        <div
          v-if="$slots['header-title']"
          class="pb-0"
          :class="`${customSecondClass} ${fullscreenClass ? 'p-3' : ''}`"
        >
          <v-layout class="d-flex flex-wrap pb-2 justify-content-between">
            <div class="pt-0 my-auto" ref="headerTitle" style="max-width: 80%">
              <slot name="header-title"></slot>
            </div>
            <v-spacer></v-spacer>
            <div class="pt-0 text-right" ref="headerAction">
              <slot name="header-action"></slot>
            </div>
          </v-layout>
          <slot name="header-tabs"></slot>
        </div>
        <slot name="body"></slot>
        <slot name="footer"></slot>
      </v-flex>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    customClass: {
      type: String,
      default: null,
    },
    fullscreenClass: {
      type: Boolean,
      default: null,
    },
    customSecondClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      pageLoading: false,
      dataLoading: false,
      headerTitleWidth: null,
      headerActionWidth: null,
    };
  },
};
</script>
