var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isActive && _vm.getPermission('attachment:view'))?_c('v-container',{staticClass:"custom-border-grey-dashed p-4 mt-2",attrs:{"fluid":""}},[_c('div',{staticClass:"d-none"},[_c('v-text-field',{attrs:{"type":"hidden","rules":[_vm.validateRules.required(!_vm.isUploading)]},model:{value:(_vm.isUploading),callback:function ($$v) {_vm.isUploading=$$v},expression:"isUploading"}})],1),_c('v-row',{class:{ 'pointer-events-none': _vm.isUploading }},[(
        _vm.lodash.isEmpty(_vm.documentArray) == false &&
        _vm.lodash.isArray(_vm.documentArray)
      )?[_vm._l((_vm.documentArray),function(document,index){return [(_vm.lodash.isEmpty(document.file) === false)?_c('v-col',{key:index,staticClass:"custom-mime-template py-0",class:{ 'custom-border-right': _vm.documentArray[index + 1] },attrs:{"md":_vm.isMinDisplay ? 2 : 2}},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',_vm._g(_vm._b({staticClass:"custom-mime-content"},'div',attrs,false),on),[(_vm.isValidImage(document.file.path))?[_c('img',{staticStyle:{"max-height":"100px"},attrs:{"width":"100%","src":document.file.url,"alt":document.file.name}})]:[_c('span',{staticClass:"svg-icon svg-icon-lg custom-mime-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL(
                            'media/mime/' +
                              _vm.getFileExtension(document.file.path) +
                              '.svg'
                          )}})],1)],(hover)?_c('div',{staticClass:"custom-mime-action text-center"},[(_vm.getPermission('attachment:delete'))?[(_vm.allowDelete)?_c('v-btn',{staticClass:"mx-2 custom-bold-button",attrs:{"color":"red lighten-1","outlined":"","small":"","loading":_vm.isDeleting(index),"disabled":_vm.primaryLoader || _vm.isDeleting(index),"icon":""},on:{"click":function($event){return _vm.deleteAPIFile(document.id, index, _vm.documentArray)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]:_vm._e(),(_vm.getPermission('attachment:view'))?[(_vm.allowDownload)?_c('v-btn',{staticClass:"mx-2 custom-bold-button",attrs:{"color":"cyan","outlined":"","small":"","loading":_vm.isDownloading(index),"disabled":_vm.primaryLoader || _vm.isDownloading(index),"icon":""},on:{"click":function($event){return _vm.downloadAPIFile(
                            document.id,
                            document.file.name,
                            index
                          )}}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]:_vm._e()],2):_vm._e()],2)]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v(_vm._s(document.file.name))])])],1):_vm._e()]})]:_vm._e(),(_vm.getPermission('attachment:create'))?[(_vm.allowUpload && _vm.documentArray.length < _vm.uploadCountLimit)?_c('v-col',{staticClass:"py-0",class:{
          'custom-border-left': _vm.lodash.isEmpty(_vm.documentArray) === false,
        },attrs:{"md":_vm.documentArray.length > 0 ? 3 : 12}},[_c('div',{staticClass:"d-flex justify-center text-center custom-grey-border",staticStyle:{"height":"100%"}},[(_vm.isUploading)?_c('v-progress-circular',{staticClass:"my-6",attrs:{"size":70,"width":7,"color":"grey lighten-1","indeterminate":""}}):_c('p',{staticClass:"p-2 my-auto font-size-16 font-weight-500 py-6 cursor-pointer",on:{"click":function($event){return _vm.$refs.attachment.$refs.input.click()}}},[_vm._v(" Click here to select file ")])],1),_c('div',{staticClass:"d-none"},[_c('v-file-input',{ref:"attachment",on:{"change":_vm.uploadFile}})],1)]):_vm._e()]:_vm._e()],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }